// @format

let appVariables = {
  allSelects: null
};

export function initCsvSelectsListener(): void {
  setSelectpickers()
}

function setSelectpickers() {
  appVariables.allSelects = $('.csv_selectpicker');
  appVariables.allSelects.selectpicker();
  setSelects();
  appVariables.allSelects.on('changed.bs.select', function (e, clickedIndex) {
    rebuildOptions(clickedIndex + 1, $(this));
    validateColumns();
  });
}

function setSelects() {
  appVariables.allSelects.each(function (i): void {
    const thisSelectpicker = $(this);
    const index = thisSelectpicker.data('selected');
    rebuildOptions(index, thisSelectpicker);
    return;
  });
}

function rebuildOptions(index, thisSelectpicker) {
  const clickedOption = thisSelectpicker.find(`option:nth-child(${index})`);
  const selectedIndex = thisSelectpicker.data('selected');
  thisSelectpicker.val(clickedOption.val());
  thisSelectpicker.find('option').removeAttr('selected');
  appVariables.allSelects.find(`option:nth-child(${selectedIndex})`).removeAttr('disabled');
  clickedOption.attr('selected', 'selected');
  if (index != 1) {
    appVariables.allSelects.find(`option:nth-child(${index})`).attr('disabled', 'disabled');
  }
  thisSelectpicker.data('selected', index);
  thisSelectpicker.data('value', clickedOption.val());
  appVariables.allSelects.selectpicker('refresh');
  fillColumnTitles(thisSelectpicker);
}

function fillColumnTitles(thisSelectpicker) {
  const colTitlesEl = $('#csv_batch_column_titles')
  const colTitles = colTitlesEl.val();
  if (typeof colTitles === "string") {
    let colTitlesArr = colTitles.split(',')
    colTitlesArr[parseInt(thisSelectpicker.data("id"))] = thisSelectpicker.data("value")
    colTitlesEl.val(colTitlesArr.join());
  }
}

function validateColumns(){
  $.post("validate_columns",{column_titles: $('#csv_batch_column_titles').attr('value')}, function(data) {
    const infoBox = $('.flow-info-box.error')
    if (!!data['errors'] && data['errors'].length > 0) {
      infoBox.removeClass('hidden')
      infoBox.find('span').html(data['errors'])
    } else{
      infoBox.addClass('hidden')
    }
  })
}