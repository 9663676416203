// @format

import 'bootstrap';
import 'bootstrap-select';

export function initCustomSelect(): void {
  $('select.brief').selectpicker('refresh').on('change',function (){
    $('.hide-in-modal').removeClass('active');
    $(`#${$(this).val()}`).addClass('active');
  })
}