// @format

var bookingMeetingForSdrdbCompanyId;
declare var Calendly: any;
export function initBookMeeting(): void {
  $(document).on("click", ".book-meeting", e => {
    const t = $(e.currentTarget);
    bookingMeetingForSdrdbCompanyId = t.data("sdrdb-company-id");
    console.log(bookingMeetingForSdrdbCompanyId);
    Calendly.initPopupWidget({
      url: "https://calendly.com/d/mywy-7z85/jocke-testar-round-robin&hide_event_type_details=1&hide_gdpr_banner=1",
      prefill: {
        name: t.data("name"),
        email: t.data("email-address")
      }
    });
  });

  window.addEventListener("message", function(e) {
    if (isCalendlyBookedEvent(e)) {
      $.ajax({
        url: "/sdrdb/booked_meeting",
        contentType: "application/json; charset=UT-8",
        dataType: "json",
        data: JSON.stringify({
          authenticity_token: $('[name="csrf-token"]').attr("content"),
          sdrdb_company_id: bookingMeetingForSdrdbCompanyId,
          calendly_uri: e.data.payload.event.uri
        }),
        type: "POST"
      });
    }
  });
}

function isCalendlyBookedEvent(e) {
  return e.data.event && e.data.event.indexOf("calendly.event_scheduled") === 0;
}
