// @format

import {requireEmailAddress} from './form_shake';

export function initLoginForm(): void {
  $('#connect_form').submit(e => {
    if (requireEmailAddress(e)) {
      $('.email-form').css({opacity: 0.5});
      $('#connect_form input[type="submit"]').prop('disabled', true);
    }
  });
}
