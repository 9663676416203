// @format

export function initCRMHiddenListener(): void {
  $('.load-status-breakdown').click(e => {
    const t = $(e.target);
    t.html('Loading ...');
    t.prop('disabled', true);
  });
  $('.toggle-hidden').click(e => {
    const t = $(e.target);
    t.html('Loading ...');
    $.ajax({
      url: '/crm/hidden_for_user_company?id=' + t.data('user-company-id'),
      success: function(data) {
        const hidden_bots = t.siblings('.hidden-bots');
        hidden_bots.html(data);
        t.css('display', 'none');
        hidden_bots.css('display', 'block');
      },
    });
  });
}
