// @format
export function initAutoExpandToggle(): void {
  $(document).on('change', '.auto-expand-toggle', e => {
    const t = $(e.currentTarget);

    $.ajax({
      url: '/targetings/' + t.data('targeting-id') + '/auto_expand',
      contentType: 'application/json; charset=UT-8',
      dataType: 'json',
      data: JSON.stringify({
        authenticity_token: $('[name="csrf-token"]').attr('content'),
        auto_expand: t.prop('checked'),
      }),
      type: 'PATCH',
    });
  });
}
