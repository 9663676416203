// @format

export function initTooltips() {
  $(document).on('mouseenter', '.trigger-tooltip', e => {
    let t = $(e.currentTarget);
    let tooltip = $('.' + t.data('tooltip-class'));
    tooltip.css('display', 'block');
    tooltip.css('top', t.offset().top + t.height() * 1.7);
    tooltip.css('left', t.offset().left);
  });

  $(document).on('mouseleave', '.trigger-tooltip', e => {
    let t = $(e.currentTarget);
    let tooltip = $('.' + t.data('tooltip-class'));
    tooltip.css('display', 'none');
  });
}
