// @format

import {showNotice} from './notice';

export function initNotifier(): void {
  notifier()
}

const notifier = () => {
  const element = $(".with-ajax-notice");
  element.on("ajax:success", (event) => { proceedSuccessNotice(event.detail[0]) });
  element.on("ajax:error", (event) => {  proceedErrorNotice(event.detail[0]) })
}

export function proceedSuccessNotice(data) : void {
  const alert = data.alert
  const notice = data.notice
  const redirect_url = data.redirect
  if(alert && alert.length > 0) {
    showNotice(data.alert)
  } else if(notice && notice.length > 0) {
    showNotice(data.notice, 'success')
  }
  if (redirect_url && redirect_url.length > 0) {
    window.location.replace(`${window.location.protocol}//${window.location.host}${redirect_url}`);
  }
}

export function proceedErrorNotice(data) : void {
  console.log(`AJAX: ${data}`)
}