// @format

import 'bootstrap';
import 'bootstrap-select';

export function initSelectpicker(): void {
  $('.selectpickerr').selectpicker('refresh');
}

export function destroySelectpicker(): void {
  $('.selectpicker').selectpicker();
  $('.selectpickerr').selectpicker();
}
