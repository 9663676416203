// @format

export function initClipCopy(): void {
  const readVariable = $('span.variable')
  readVariable.unbind().on('click', (e) => copyVariable($(e.currentTarget)) )
  $(".copyable").unbind().on('click', (e) => copy($(e.currentTarget)) )
}

const copy = (target) => {
  const dataValue = target.data("copy");
  navigator.clipboard.writeText(dataValue)
}

const copyVariable = (target) => {
  target.off('click');
  const dataValue = target.text();
  if (dataValue) {
    navigator.clipboard.writeText(dataValue)
      .then(() => {
        copyAnimate(target, dataValue);
      })
      .catch(err => {
        console.log('Something went wrong', err);
      })
  }
}

function copyAnimate(target,dataValue) {
  target.css('width', target.css('width'))
  target.addClass('copied')
  target.text('Copied!')
  setTimeout(() => {target.animate({opacity: 0.2}, 300)}, 1000)
  setTimeout(() => {
    target.text(dataValue)
    target.animate({opacity: 1}, 200)
    target.css('width', 'auto')
    target.removeClass('copied')
    target.on('click', () => copyVariable(target));
  }, 1200);
}
