export function initCompanyDomains() {
  $(".add-manually-verified-domain").bind("ajax:success", function(event) {
    $(event.target).addClass("disabled").text("✓ Verified")
    $(event.target).parent(".domain").find(".add-manually-rejected-domain").removeClass("disabled").text("✗ Reject")
  })

  $(".add-manually-rejected-domain").bind("ajax:success", function(event) {
    $(event.target).addClass("disabled").text("✗ Rejected")
    $(event.target).parent(".domain").find(".add-manually-verified-domain").removeClass("disabled").text("✓ Verify")
  })
}
