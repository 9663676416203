export function initTurboFixes() {
  document.addEventListener('turbo:before-fetch-request', (event) => {
    // Turbo Drive does not send a referrer like turbolinks used to, so let's simulate it here
    event.detail.fetchOptions.headers['Turbo-Referrer'] = window.location.href
    event.detail.fetchOptions.headers['X-Turbo-Nonce'] = $("meta[name='csp-nonce']").prop('content')
  })

  document.addEventListener("turbo:before-cache", function() {
    let scriptTagsToAddNonces = document.querySelectorAll("script[nonce]");
    for (var element of scriptTagsToAddNonces) {
      element.setAttribute('nonce', element.nonce);
    }
  });
}
