// @format

import 'bootstrap-datepicker';
import 'bootstrap-select';

export function initDatePicker(): void {
  $(".datepicker").datepicker({
    format: "yyyy-mm-dd",
    startDate: tomorrow()
  }).on("changeDate", function(e) {
    $(".qlfr-modal-error").css("display", "none");
    $(".qlfr-modal-submit").prop("disabled", false);
  }).on("clearDate", function(e) {
    $(".qlfr-modal-error").css("display", "inline-block");
    $(".qlfr-modal-submit").prop("disabled", true);
  });
}

function tomorrow() {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);

  return currentDate;
}
