// @format

jQuery.fn.shake = function(
  intShakes: number,
  intDistance: number,
  intDuration: number,
): JQuery {
  this.each(function() {
    $(this).css('position', 'relative');
    for (var x = 1; x <= intShakes; x++) {
      $(this)
        .animate({left: intDistance * -1}, intDuration / intShakes / 4)
        .animate({left: intDistance}, intDuration / intShakes / 2)
        .animate({left: 0}, intDuration / intShakes / 4);
    }
  });
  return this;
};

export function requireEmailAddress(e): boolean {
  const t = $(e.currentTarget);
  const email_address = <string>t
    .find('input[type=text]')
    .first()
    .val();
  if (!email_address.includes('@')) {
    t.shake(3, 3, 600);
    e.preventDefault();
    return false;
  } else {
    return true;
  }
}
