// @format

export function initFileUpload(): void {
  setUploadedFileName()
}

function setUploadedFileName() {
  $('.bootstrap-file-input').on('change',function(){
    const fileName = $(this).val();
    if (typeof fileName === "string") {
      const parsedFileName = fileName.match(/^.*\\(.*)/)[1]
      $(this).next('.custom-file-label').html(parsedFileName);
    }
  })
}