// @format

import {loadStripe} from '@stripe/stripe-js/dist/stripe.js';

export async function initStripeListener(): Promise<void> {
const stripe = await loadStripe($("meta[name='stripe-key']").attr("content"));
  $(document).on("click", ".checkout", e => {
    e.preventDefault();
    let planId = e.target.getAttribute("data-payment-plan-id");
    let creditBatchSubscriptionId = e.target.getAttribute("data-credit-batch-subscription-id");
    createCheckoutSession(planId, creditBatchSubscriptionId).then(function(data) {
      stripe
        .redirectToCheckout({
          sessionId: data.session_id
        })
    });
  });
}

var createCheckoutSession = function(planId, creditBatchSubscriptionId) {
  return fetch("/stripe/create_checkout_session", {
    credentials: "same-origin",
    headers: {
      "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest"
    },
    method: "POST",
    body: JSON.stringify({
      credit_batch_subscription_id: creditBatchSubscriptionId,
      payment_plan_id: planId
    })
  }).then(function(result) {
    return result.json();
  });
};
