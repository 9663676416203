// @format

import Choices from 'choices.js/assets/scripts/dist/choices.min.js';
import {setDisallowedWarnListener} from "./disallowed_warn_listener";

export function initChoices(): void {
  const options = {
    shouldSort: false,
    removeItems: true,
    removeItemButton: true,
    resetScrollPosition: false,
    placeholder: true,
  };

  if ($('#credit_batch_type').length > 0) {
    let choicesInstance = new Choices('#credit_batch_type', options);
    setOnBlurOrTab(choicesInstance);
  }

  if ($('#credit_batch_subscription_payment_plan_id').length > 0) {
    let choicesInstance = new Choices('#credit_batch_subscription_payment_plan_id', options);
    setOnBlurOrTab(choicesInstance);
  }

  if ($('.email-responses-form #status').length > 0) {
    let choicesInstance = new Choices('.email-responses-form #status', options);
    setOnBlurOrTab(choicesInstance);
  }

  if ($('.email-responses-form #company_id').length > 0) {
    let choicesInstance = new Choices('.email-responses-form #company_id', options);
    setOnBlurOrTab(choicesInstance);
  }

  if ($('.email-responses-form #credit_batch_id').length > 0) {
    let choicesInstance = new Choices('.email-responses-form #credit_batch_id', options);
    setOnBlurOrTab(choicesInstance);
  }

  if ($('.email-responses-form #language').length > 0) {
    let choicesInstance = new Choices('.email-responses-form #language', options);
    setOnBlurOrTab(choicesInstance);
  }

  if ($('#targeting_position').length > 0 || $("#search_position").length > 0) {
    let element = document.getElementById('targeting_position') || document.getElementById("search_position");
    let choicesInstance = new Choices(element, options)
    let suggestedElement = $('.suggested-positions')
    let labelElement = $('.suggested-positions-label')
    var suggestions = []
    var lastAdded

    let refreshSuggestions = function() {
      suggestedElement.empty()

      console.log(suggestions.length)
      if (suggestions.length) {
        labelElement.addClass('show')
      } else {
        labelElement.removeClass('show')
      }

      $.each(suggestions.slice(0, 5), function(idx, position) {
        let elem = $("<div class='choices__item'></div>").text(position).click(function() {
          lastAdded = position
          choicesInstance.setValue([position])
          $(this).remove()
          suggestions.splice(idx, 1)
          refreshSuggestions()
        })

        suggestedElement.append(elem)
      })
    }

    setOnBlurOrTab(choicesInstance);
    splitOnpaste(choicesInstance);

    element.addEventListener(
      'addItem',
      function(event) {
        let newPosition = (<CustomEvent>event).detail.value
        let addedPositions = choicesInstance.getValue(true).map(function(s) {
          return s.toLowerCase()
        })

        if (newPosition != lastAdded) {
          $.get(
            '/targetings/suggested_positions',
            { position: newPosition },
            function(data) {
              $.each(data.reverse(), function(_, elem) {
                if (!addedPositions.includes(elem.toLowerCase()) && !suggestions.includes(elem)) {
                  suggestions.unshift(elem)
                }
              })

              refreshSuggestions()
            }
          )
        }
      },
      false)
  }

  if ($('#targeting_location').length > 0) {
    let element = document.getElementById('targeting_location')
    let choicesInstance = new Choices(element, options);
    setOnBlurOrTab(choicesInstance);
    splitOnpaste(choicesInstance);
    setDisallowedWarnListener(element);
  }

  if ($('#targeting_backend_location').length > 0) {
    let choicesInstance = new Choices('#targeting_backend_location', options);
    splitOnpaste(choicesInstance);
    setOnBlurOrTab(choicesInstance);
  }

  if ($('#search_location').length > 0) {
    let choicesInstance = new Choices('#search_location', options);
    splitOnpaste(choicesInstance);
    setOnBlurOrTab(choicesInstance);
  }

  if ($('#targeting_backend_position').length > 0) {
    let choicesInstance = new Choices('#targeting_backend_position', options);
    splitOnpaste(choicesInstance);
    setButtonsListener(choicesInstance);
  }

  if ($('#targeting_industries').length > 0) {
    let choicesInstance = new Choices('select#targeting_industries', options);
    setButtonsListener(choicesInstance);
    handleAddBundle(choicesInstance);
  }

  if ($('#search_industries').length > 0) {
    let choicesInstance = new Choices('select#search_industries', options);
    setButtonsListener(choicesInstance);
    handleAddBundle(choicesInstance);
  }

  if ($('#targeting_backend_industries').length > 0) {
    let choicesInstance = new Choices('select#targeting_backend_industries', options);
    setButtonsListener(choicesInstance);
    handleAddBundle(choicesInstance);
  }

  if ($('#targeting_head_counts').length > 0) {
    let choicesInstance = new Choices('select#targeting_head_counts', options);
    setButtonsListener(choicesInstance);
  }

  if ($('#search_head_counts').length > 0) {
    let choicesInstance = new Choices('select#search_head_counts', options);
    setButtonsListener(choicesInstance);
  }

  if ($('#targeting_backend_head_counts').length > 0) {
    let choicesInstance = new Choices('select#targeting_backend_head_counts', options);
    setButtonsListener(choicesInstance);
  }

  if ($('#auto_query_industries').length > 0) {
    let choicesInstance = new Choices('#auto_query_industries', options);
    setButtonsListener(choicesInstance);
    handleAddBundle(choicesInstance);
  }

  if ($('#auto_query_company_sizes').length > 0) {
    let choicesInstance = new Choices('#auto_query_company_sizes', options);
    setButtonsListener(choicesInstance);
  }

  if ($('#auto_query_seniority_levels').length > 0) {
    let choicesInstance = new Choices('#auto_query_seniority_levels', options);
    setButtonsListener(choicesInstance);
  }

  function runWithLoading(choicesWrapper, button, callback) {
    button.text(button.data('loading')).addClass('disabled');
    setTimeout(() => {
      callback();
      button.text(button.data('btn-name')).removeClass('disabled');
    }, 1);
  }

  function setButtonsListener(choicesInstance) {
    const choicesWrapper = $(choicesInstance.containerOuter);
    const buttonsWrapper = choicesWrapper.siblings('.choices-change-all');

    buttonsWrapper.find('.select-all').on('click', e => {
      const choicesItems = choicesWrapper
        .find('.choices__item--choice')
        .map(function() {
          return $(this).data('value');
        });
      if (choicesItems.length == 0) return false;
      runWithLoading(choicesWrapper, $(e.target), () => {
        choicesInstance.clearStore();
        choicesInstance.setValue(choicesItems);
      });
    });

    if (choicesInstance.baseId.endsWith("industries")) {
      buttonsWrapper.find('.deselect-all').on('click', e => {
        $(".add-bundled-choices").each(function(_, elem) {
          setBundleSelectMode(elem)
        })
      });
    }

    buttonsWrapper.find('.deselect-all').on('click', e => {
      if (
        choicesWrapper.find('.choices__inner .choices__item--selectable')
          .length == 0
      )
        return false;
      runWithLoading(choicesWrapper, $(e.target), () => {
        choicesInstance.removeActiveItems();
      });
    });
  }

  function setOnBlurOrTab(choicesInstance) {
    choicesInstance.input.addEventListener('blur', function(target) {
      setChoicesValue(choicesInstance);
    });
    choicesInstance.input.addEventListener('keydown', function(event) {
      // set value on tab
      if (event.keyCode == 9) {
        setChoicesValue(choicesInstance);
      }
    });
  }

  function setChoicesValue(choicesInstance) {
    const value = choicesInstance.input.value;
    if (value) {
      choicesInstance.setValue([value]);
      choicesInstance.clearInput();
      choicesInstance.hideDropdown(true);
    }
  }

  function splitOnpaste(choicesInstance) {
    choicesInstance.input.addEventListener(
      'paste',
      function(event) {
        let paste = event.clipboardData.getData('text')
        let newPositions = paste.trim().split(/\r?\n/)
        choicesInstance.setValue(newPositions)
        event.preventDefault()
      }
    )
  }

  function handleAddBundle(choicesInstance) {
    $(".add-bundled-choices").click(function(event) {
      event.preventDefault()

      let elem = event.target
      let bundle = JSON.parse(elem.dataset.industries)
      let added = choicesInstance.getValue(true)

      if (elem.dataset.added == "true") {
        let shouldKeep = added.filter(x => !bundle.includes(x));
        choicesInstance.clearStore()
        choicesInstance.setValue(shouldKeep)
        setBundleSelectMode(elem)
      } else {
        let shouldAdd = bundle.filter(x => !added.includes(x));
        choicesInstance.setValue(shouldAdd)
        setBundleDeselectMode(elem)
      }
    })
  }

  function setBundleSelectMode(elem) {
    elem.innerText = elem.innerText.replace("-", "+")
    elem.setAttribute("data-added", "false")
  }

  function setBundleDeselectMode(elem) {
    elem.innerText = elem.innerText.replace("+", "-")
    elem.setAttribute("data-added", "true")
  }
}
