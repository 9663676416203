// @format

import 'bootstrap-datepicker';
import 'bootstrap-select';

export function initScheduleDate(): void {
  $('.schedule-contact-btn').on('click',function (e){
    e.preventDefault();
    const wrapper = $(this).closest('.schedule-contact');
    const submit = wrapper.find('.submit-schedule')
    wrapper.datepicker({
      format: "yyyy-mm-dd",
      clearBtn: true,
      autoclose: true,
      startDate: new Date(),
      todayHighlight: true
    }).on("changeDate", () => {
      const value = submit.val().toString()
      if ( value.length > 0 ) {
        wrapper.find('.schedule-contact-btn').text("Follow-up scheduled for " + value);
        submit.trigger("click");
      }
    }).on('clearDate', ()=> {
      wrapper.find('.schedule-contact-btn').text('Schedule follow-up');
      submit.trigger("click");
    })
  })
}
