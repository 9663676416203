// @format

export function initCommentSidebar() {
  var params = new URLSearchParams(window.location.search);
  loadIfCommentsPresent()
  if (params.get('comment_sidebar_user_company_id')) {
    loadCommentSidebar(
      params.get('comment_sidebar_user_company_id'),
      params.get('comment_sidebar_bot_id'),
      params.get('comment_sidebar_targeting_id'),
      params.get('comment_sidebar_auto_query_id'),
    );
  }
  $(document).on('click', '.open-comment-sidebar', e => {
    let t = $(e.currentTarget);
    loadCommentSidebar(t.data('user-company-id'), t.data('bot-id'), t.data('targeting-id'), t.data('auto-query-id'));
  });
  $(document).on('click', '.close-comment-sidebar', e => {
    $(".comment-sidebar").hide()
  });
}

function loadIfCommentsPresent(){
  let t = $('.open-comment-sidebar')
  if (window.location.href.indexOf("auto_queries") > -1 && t && t.hasClass('auto-query-comments')){
    loadCommentSidebar(t.data('user-company-id'), t.data('bot-id'), t.data('targeting-id'), t.data('auto-query-id'));
  } else if (window.location.href.indexOf("edit_backend") > -1 && t && t.hasClass('targeting-comments')) {
    loadCommentSidebar(t.data('user-company-id'), t.data('bot-id'), t.data('targeting-id'), t.data('auto-query-id'));
  }
}

function loadCommentSidebar(userCompanyId: string, botId: string, targetingId: string, autoQueryId: string) {
  if (history.pushState) {
    var params = new URLSearchParams(window.location.search);
    params.set('comment_sidebar_user_company_id', userCompanyId);
    params.set('comment_sidebar_bot_id', botId);
    params.set('comment_sidebar_targeting_id', targetingId);
    params.set('comment_sidebar_auto_query_id', autoQueryId);
    var newUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      params.toString();
    window.history.replaceState({path: newUrl}, '', newUrl);
  }
  $.ajax({
    url:
      '/comments/sidebar?user_company_id=' + userCompanyId + '&bot_id=' + botId + '&targeting_id' + targetingId + '&auto_query_id=' + autoQueryId,
    success: function(jqXHR) {
      $('.comment-sidebar').remove();
      $('body').prepend(jqXHR);
    },
  });
}
